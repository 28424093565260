import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Image, Divider } from 'antd';
import { Col, Row } from 'antd';
import '../styles/Login.css';
import mano from '../img/mano.jpg';
import favicon from '../img/favicon.svg';
import { useNavigate } from "react-router-dom";

function Login() {
    const navigate = useNavigate();
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        navigate("/dashboard");
      };
    return(
        <Row>
            <Col span={12}>
                <div className='centrado'>
                    <Image
                        width={400}
                        src={mano}
                    />
                </div>
            </Col>
            <Col span={12}>
                <div className='centrado fondo-amarillo' style={{textAlign: 'center'}}>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        >
                        <img src={favicon} alt="favicon" width="200" height="200" />
                        <Form.Item
                            name="username"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                            ]}
                        >
                            <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Contraseña"
                            />
                        </Form.Item>
                        <Form.Item>
                            <a className="login-form-forgot" href="">
                            Obtener login OTP
                            </a>
                            <a style={{paddingLeft: '10px'}} className="login-form-forgot" href="">
                            Has olvidado tu contraseña?
                            </a>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                            Inicia Sesión
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Divider style={{color: '#fff'}}>No he tenido cuenta?</Divider>
                        </Form.Item>
                        <Form.Item style={{textAlign: 'center'}}>
                            <a className='btn-registrar'>Regístrate</a>
                        </Form.Item>
                        <Form.Item className='form-condiciones'>
                            <p className='condiciones'>Al iniciar sesión, acepta realizar todas las transacciones de acuerdo con 
                                <a href='#' style={{color: '#fff'}}> condiciones de uso y política de Bitel</a>
                            </p>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </Row>
    )
}
export default Login;
