import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Companys from "./components/Companys";
import Reportes from "./components/Reportes";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/companys" element={<Companys />} />
        <Route path="/dashboard/reportes" element={<Reportes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
