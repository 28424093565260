import { Space, Button, Dropdown, Input, Spin } from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const { Search } = Input;

const items = [
  {
    label: 'Activo',
    key: '1',
    icon: <UserOutlined />,
  },
  {
    label: 'Inactivo',
    key: '0',
    icon: <UserOutlined />,
  },
];

function compareArrays(arr1, arr2) {
  for (let i = 0; i < arr2.length; i++) {
        var cont_id = i+1
        if(arr1[i] === undefined){
          axios.post("https://softys-back-43da3c7263c0.herokuapp.com/sku/info/", {
                sector_general: arr2[i]['sector_general'],
                sector_detallado: arr2[i]['sector_detallado'],
                marca_detalle: arr2[i]['marca_detalle'],
                imagen_sku: arr2[i]['imagen_sku']
              })
              .then((response) => {
                console.log("resp: ", response);
              });

        }else {
          if (arr1[i]['sector'] !== arr2[i]['sector_general']) {
            console.log("sector_general: ", arr2[i]['sector_general'])
            axios.put("https://softys-back-43da3c7263c0.herokuapp.com/sku/", {
              sector_general: arr2[i]['sector_general'],
              sector_detallado: arr1[i]['detalle'],
              marca_detalle: arr1[i]['marca'],
              imagen_sku: arr1[i]['imagen'],
              id_sku: cont_id
            })
            .then((response) => {
              console.log(response);
  
            });
          }
          if (arr1[i]['detalle'] !== arr2[i]['sector_detallado']) {
            console.log("sector_detallado: ", arr2[i]['sector_detallado'])
            axios.put("https://softys-back-43da3c7263c0.herokuapp.com/sku/", {
              sector_general: arr1[i]['sector'],
              sector_detallado: arr2[i]['sector_detallado'],
              marca_detalle: arr1[i]['marca'],
              imagen_sku: arr1[i]['imagen'],
              id_sku: cont_id
            })
            .then((response) => {
              console.log(response);
  
            });
          }
          if (arr1[i]['marca'] !== arr2[i]['marca_detalle']) {
            console.log("marca_detalle: ", arr2[i]['marca_detalle'])
            axios.put("https://softys-back-43da3c7263c0.herokuapp.com/sku/", {
              sector_general: arr1[i]['sector'],
              sector_detallado: arr1[i]['detalle'],
              marca_detalle: arr2[i]['marca_detalle'],
              imagen_sku: arr1[i]['imagen'],
              id_sku: cont_id
            })
            .then((response) => {
              console.log(response);
  
            });
          }
          if (arr1[i]['imagen'] !== arr2[i]['imagen_sku']) {
            console.log("imagen_sku: ", arr2[i]['imagen_sku'])
            axios.put("https://softys-back-43da3c7263c0.herokuapp.com/sku/", {
              sector_general: arr1[i]['sector'],
              sector_detallado: arr1[i]['detalle'],
              marca_detalle: arr1[i]['marca'],
              imagen_sku: arr2[i]['imagen_sku'],
              id_sku: cont_id
            })
            .then((response) => {
              console.log(response);
  
            });
          }
        }
  }
  return null; // No differences found
}

const suffix = (
  <></>
);

function Tabla() {
    const [data, setData] = useState([]);
    const [primero, setPrimero] = useState(0);
    const [ultimo, setUltimo] = useState(4);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleMenuClick = (e) => {
      axios.get("https://softys-back-43da3c7263c0.herokuapp.com/sku/verify/"+e['key'])
            .then((response) => {
              console.log("dataaaa: ", response['data']['resp']['data']);
              if(response['data']['resp']['data'] !== undefined){
                setData(response['data']['resp']['data']);
              }
            })
            .catch((error) => console.log(error));
    };
    
    const menuProps = {
      items,
      onClick: handleMenuClick,
    };

    useEffect(() => {
        axios.get("https://softys-back-43da3c7263c0.herokuapp.com/sku/info/")
        .then((response) => {
          console.log("dataaaa: ", response['data']['resp']['data']);
          if(response['data']['resp']['data'] !== undefined){
            setData(response['data']['resp']['data']);
          }
        })
        .catch((error) => console.log(error));
    }, []);

    const handleFileUpload = (e) => {
      setLoading(true);
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e) => {
            const datoo = e.target.result;
            const workbook = XLSX.read(datoo, {type: "binary"});
            const sheetName = workbook.SheetNames[1];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);

            console.log("array 1: ", data);
            console.log("array 2: ", parsedData);
            const difference = compareArrays(data, parsedData);
            if (difference) {
                console.log(`Difference found at index ${difference.index}, field: ${difference.field}`);
            } else {
                console.log("Arrays are identical.");
            }
            setTimeout(function() {
              axios.get("https://softys-back-43da3c7263c0.herokuapp.com/sku/info/")
              .then((response) => {
                console.log("dataaaa: ", response['data']['resp']['data']);
                if(response['data']['resp']['data'] !== undefined){
                  setData(response['data']['resp']['data']);
                  setLoading(false);
                }
              })
              .catch((error) => console.log(error));
            }, 1000);
            /*setData(parsedData);
            parsedData.map((value, index)=> {
              console.log("value: ", value);
              axios.post("https://softys-back-43da3c7263c0.herokuapp.com/sku/info/", {
                sector_general: value['sector_general'],
                sector_detallado: value['sector_detallado'],
                marca_detalle: value['marca_detalle'],
                imagen_sku: value['imagen_sku']
              })
              .then((response) => {
                console.log(response);

                if(response['data']['resp']['code'] != undefined){
                  console.log("entra undefined");
                  if(response['data']['resp']['code'] == 200){
                    axios.get("https://softys-back-43da3c7263c0.herokuapp.com/sku/info/")
                    .then((response) => {
                      console.log("dataaaa: ", response['data']['resp']['data']);
                      if(response['data']['resp']['data'] != undefined){
                        setData(response['data']['resp']['data']);
                        setLoading(false);
                      }
                    })
                    .catch((error) => console.log(error));
                  }
                }else {
                  console.log("error en la carga masiva")
                }
              });
            })*/
        }
    }

    const siguiente = () => {
      setPrimero(primero+4);
      setUltimo(ultimo+4);
      setNumberOfPages(numberOfPages+1);
    }

    const anterior = () => {
      if(primero > 0){
        setPrimero(primero-4);
        setUltimo(ultimo-4);
        setNumberOfPages(numberOfPages-1);
      }
      if(primero === 0){
        setNumberOfPages(1);
      }
    }

    const onSearch = (value, _e, info) => {
      console.log(info?.source, value);
      axios.get("https://softys-back-43da3c7263c0.herokuapp.com/sku/store/?search="+value)
            .then((response) => {
              console.log("dataaaa: ", response);
              if(response['data']['resp']['data'] !== undefined){
                setData(response['data']['resp']['data']);
              }
            })
            .catch((error) => console.log(error));
    }

    return(
        <>
          <Spin spinning={loading} tip="Cargando..." size="large">
            <input 
                type='file'
                accept='.xlsx, .xls'
                onChange={handleFileUpload}
            />
            <br/>
            <br/>
            <Button type="primary"  onClick={anterior}>
              Anterior
            </Button>
            <Button style={{marginLeft: '15px', marginRight: '15px'}} type="primary" onClick={siguiente}>
              Siguiente
            </Button>
            <Dropdown menu={menuProps}>
              <Button>
                <Space>
                  Estado
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Space>
              <Search
                placeholder="Busca un producto"
                enterButton="Search"
                size="medium"
                suffix={suffix}
                onSearch={onSearch}
                style={{verticalAlign: 'middle', marginLeft: '15px'}}
              />
            </Space>
            <Space><a href="https://softys.devscorp.pe/sku.xlsx" download="ejemplo.xlsx" className="boton-descarga">Descargar Ejemplo</a></Space>
            {data.length > 0 && (
              <>
                <table>
                    <thead>
                        <tr key="1">
                        {Object.keys(data[0]).map((key) => (
                            <th key={key}>{key}</th>
                        ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                          index >= primero && index < ultimo ? 
                          <tr key={index}>
                            {Object.values(row).map((value, index) => (
                                <td key={index}>
                                  {index === 0 ? <img src={value} alt="img" width="50" height="50"/>: value}
                                </td>
                            ))}
                          </tr>
                          :
                          <></>
                        ))}
                    </tbody>
              </table>
              <h3>Pagina: {numberOfPages}</h3>
              </>
            )}
            </Spin>
        </>
    )
}
export default Tabla;