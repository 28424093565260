import { Space, Button, Dropdown, Input, Spin } from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';


function TablaCompanys() {
    const [data, setData] = useState([]);
    const [primero, setPrimero] = useState(0);
    const [ultimo, setUltimo] = useState(4);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        axios.get("https://softys-back-43da3c7263c0.herokuapp.com/sku/reportes/")
        .then((response) => {
          console.log("dataaaa: ", response['data']['resp']['data']);
          if(response['data']['resp']['data'] != undefined){
            setData(response['data']['resp']['data']);
          }
        })
        .catch((error) => console.log(error));
    }, []);

    const siguiente = () => {
      setPrimero(primero+4);
      setUltimo(ultimo+4);
      setNumberOfPages(numberOfPages+1);
    }

    const anterior = () => {
      if(primero > 0){
        setPrimero(primero-4);
        setUltimo(ultimo-4);
        setNumberOfPages(numberOfPages-1);
      }
      if(primero == 0){
        setNumberOfPages(1);
      }
    }

    return(
        <>
        <Spin spinning={loading} tip="Cargando..." size="large">
            <Button type="primary"  onClick={anterior}>
              Anterior
            </Button>
            <Button style={{marginLeft: '15px'}} type="primary" onClick={siguiente}>
              Siguiente
            </Button>
            {data.length > 0 && (
              <>
                <table>
                    <thead>
                        <tr>
                        {Object.keys(data[0]).map((key) => (
                            <th key={key}>{key}</th>
                        ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                          index >= primero && index < ultimo ? 
                          <tr key={index}>
                            {Object.values(row).map((value, index) => (
                                <td key={index}>
                                  {index === 5 ? <img src={value} alt="img" width="50" height="50"/>: value}
                                </td>
                            ))}
                          </tr>
                          :
                          <></>
                        ))}
                    </tbody>
              </table>
              <h3>Pagina: {numberOfPages}</h3>
              </>
            )}
          </Spin>
        </>
    )
}
export default TablaCompanys;