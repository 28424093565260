
import Contenido from "./Contenido";
import '../styles/Dashboard.css';

function Companys() {
    return(
        <Contenido title="Tiendas" />
    )
}
export default Companys;
