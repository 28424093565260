
import Contenido from "./Contenido";
import '../styles/Dashboard.css';

function Dashboard() {
    return(
        <Contenido title="Skus" />
    )
}
export default Dashboard;
