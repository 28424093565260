import React, { useState } from 'react';
import axios from 'axios';
import {message } from 'antd';
import Tabla from "./Tabla";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  HomeOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Breadcrumb } from 'antd';
import { useNavigate } from "react-router-dom";
import TablaCompanys from './TablaCompanys';
import TablaReportes from './TablaReportes';
import favicon from '../img/favicon.svg';
const { Header, Sider, Content } = Layout;




const props = {
  name: 'file',
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log("aquii");
      console.log(info.file, info.fileList);
      axios.post("http://localhost:8080/upload", {
        document: info.file,
        lista: info.fileList
      })
      .then((response) => {
        console.log(response);
      });
    }
    if (info.file.status === 'done') {
      console.log("1")
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      console.log("2")
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const Contenido = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [key, setKey] = useState('1');
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onClickMenu = (e) => {
    console.log('click ', e);
    console.log("'"+e['key']+"'")
    setKey("'"+e['key']+"'");
    if(e['key'] == 1){
      navigate("/dashboard");
    }
    if(e['key'] == 2){
      navigate("/dashboard/companys");
    }
    if(e['key'] == 3){
      navigate("/");
    }
    if(e['key'] == 4){
      navigate("/dashboard/reportes");
    }
  };

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[key]}
          onClick={onClickMenu}
          items={[
            {
              key: '0',
              icon: <img src={favicon} alt="favicon" width="80" height="80" />
            },
            {
              key: '5',
              icon: <></>
            },
            {
              key: '1',
              icon: <UserOutlined />,
              label: 'Ordenes',
            },
            {
              key: '2',
              icon: <VideoCameraOutlined />,
              label: 'Tiendas',
            },
            {
              key: '4',
              icon: <UploadOutlined />,
              label: 'Reportes',
            },
            {
              key: '3',
              icon: <UploadOutlined />,
              label: 'Cerrar Sesion',
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Breadcrumb
            items={[
              {
                href: '',
                title: <HomeOutlined />,
              },
              {
                href: '',
                title: (
                  <>
                    <UserOutlined />
                    <span>Gestionar Tienda</span>
                  </>
                ),
              },
              {
                title: 'Gerente de ventas',
              },
              {
                title: props.title,
              },
            ]}
          />
          <h3>{props.title}</h3>
          {props.title === 'Skus' ? <Tabla />: props.title === 'Reportes' ? <TablaReportes /> :<TablaCompanys />}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Contenido;