
import Contenido from "./Contenido";
import '../styles/Dashboard.css';

function Reportes() {
    return(
        <Contenido title="Reportes" />
    )
}
export default Reportes;
